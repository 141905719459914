import React from "react";
import logo from "./logo.svg";
import "./App.css";

function App() {
  return (
    <div className="App">
      <h1>モニュケ</h1>
      <p>モノによるコミュニケーション</p>
      <p>
        <img src="top.jpg" width="600px" alt="Top" />
      </p>
      <a href="https://monyuke.base.shop/" target="_blank" rel="noreferrer">
        モニュケのショップ
      </a>
      {" | "}
      <a href="https://x.com/YusukeSanekata" target="_blank" rel="noreferrer">
        代表 X アカウント
      </a>
      <br />
      <br />
      ～～工事中～～
    </div>
  );
  // return (
  //   <div className="App">
  //     <header className="App-header">
  //       <img src={logo} className="App-logo" alt="logo" />
  //       <p>
  //         Edit <code>src/App.tsx</code> and save to reload.
  //       </p>
  //       <a
  //         className="App-link"
  //         href="https://reactjs.org"
  //         target="_blank"
  //         rel="noopener noreferrer"
  //       >
  //         Learn React
  //       </a>
  //     </header>
  //   </div>
  // );
}

export default App;
